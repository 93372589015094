var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { Accordion } from 'components/accordion/Accordion.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { List } from 'components/list/List.component';
import { LayoutWithColor } from 'pages/demo/components/layoutRadioButtonsWithCarColor/LayoutRadioButtonsWithCarColor.component';
import { Label } from 'components/label/Label.component';
import { ListItemElement } from 'components/listItemElement/ListItemElement.component';
import { OptionStandardChip } from 'components/chip/OptionStandardChip.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import { LackOfPriceChip } from 'components/chip/LackOfPriceChip.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { LayoutWithWheels } from 'pages/demo/components/layoutRadioButtonsWithWheels/LayoutRadioButtonsWithWheels.component';
import { TextAmountPlaceholder } from 'components/textAmount/TextAmountPlaceholder';
import { useExteriorApi } from './useExteriorApi';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { immutableSort } from 'utils/immutableSort';
import { useStatus } from 'common/statusContext';
import UserContext from 'common/userContext';
function isFromVCP() {
    return UserContext.isFromVCP() && !UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING');
}
export var Exterior = withCancellation(function (_a) {
    var exteriors = _a.exteriors, selectedColor = _a.selectedColor, onSelectedColorChange = _a.onSelectedColorChange, selectedWheels = _a.selectedWheels, onSelectedWheelsChange = _a.onSelectedWheelsChange;
    var colors = exteriors.find(function (exterior) { return exterior.category.toUpperCase() === 'COLOR' || exterior.category.toUpperCase() === 'KOLOR'; });
    var sortedColors = __assign(__assign({}, colors), { availableOptions: colors ? immutableSort(colors.availableOptions, function (colorA, colorB) {
            if (colorA.price - colorB.price !== 0 && colorA.price !== null && colorB.price !== null) {
                return colorA.price - colorB.price;
            }
            else {
                return colorA.name.localeCompare(colorB.name);
            }
        }) : [] });
    var wheels = exteriors.find(function (exterior) { return exterior.category.toUpperCase() !== 'COLOR' && exterior.category.toUpperCase() !== 'KOLOR'; });
    var standardWheels = wheels.availableOptions.find(function (wheel) { return wheel.standard; });
    var nonStandardWheels = wheels.availableOptions.filter(function (wheel) { return !wheel.standard; });
    var sortedNonStandardWheels = immutableSort(nonStandardWheels, function (wheelsA, wheelsB) {
        if (wheelsA.price - wheelsB.price !== 0 && wheelsA.price !== null && wheelsB.price !== null) {
            return wheelsA.price - wheelsB.price;
        }
        else {
            return wheelsA.name.localeCompare(wheelsB.name);
        }
    });
    var sordedAvailbleOptionsOfWheels = __spreadArray([standardWheels], sortedNonStandardWheels, true);
    var sortedWheels = __assign(__assign({}, wheels), { availableOptions: __spreadArray([], sordedAvailbleOptionsOfWheels, true) });
    var useExterior = useExteriorApi();
    var getColorRequest = isFromVCP() ? useExterior.getColorVcp : useExterior.getColor;
    var _b = useState([]), colorImagesLinks = _b[0], setColorImagesLinks = _b[1];
    var setStatus = useStatus().setStatus;
    useEffect(function () {
        if (exteriors) {
            setStatus('pending');
            Promise.all(colors.availableOptions.map(function (color) { return getColorRequest(color.token, color.versionToken, color.carModelId); })).then(function (imageResponses) {
                var nextColorImageLinks = imageResponses.map(function (imageResponse, index) {
                    var colorId = colors.availableOptions[index].id;
                    var exteriorUrls = imageResponse.find(function (image) { return image.type === 'EXTERIOR'; });
                    var url = (exteriorUrls !== null && exteriorUrls !== undefined) ? exteriorUrls.urls[0] : '';
                    return {
                        colorId: colorId,
                        url: url,
                    };
                });
                setStatus('success');
                setColorImagesLinks(nextColorImageLinks);
            });
        }
    }, []);
    return (React.createElement("div", { className: "exteriorWrapper" }, exteriors ? (React.createElement(Accordion.Root, { defaultValue: [colors.category, wheels.category] },
        React.createElement(RadioGroup.Root, { value: selectedColor, onValueChange: onSelectedColorChange },
            React.createElement(Accordion.Item, { value: colors.category, key: colors.category },
                React.createElement(Accordion.Trigger, null, colors.category),
                React.createElement(Accordion.Content, null,
                    React.createElement(List.Root, null, sortedColors.availableOptions.map(function (color) { return (React.createElement(List.Item, { isSelected: color.id === selectedColor, onClick: function (event) {
                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                return;
                            }
                            onSelectedColorChange(color.id);
                        }, height: "fixed", key: color.id, isPrice: color.price !== null },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            colorImagesLinks.length > 0 ? (React.createElement(LayoutWithColor, { thumbnailImageSrc: color.image, imagePreviewSrc: colorImagesLinks.find(function (link) { return link.colorId === color.id; }).url },
                                React.createElement(RadioGroup.Item, { value: color.id, id: color.id },
                                    React.createElement(RadioGroup.Indicator, null)),
                                React.createElement(Label, { htmlFor: color.id }, color.name))) : (React.createElement(RadioGroup.Layout, null,
                                React.createElement(RadioGroup.Item, { value: color.id, id: color.id },
                                    React.createElement(RadioGroup.Indicator, null)),
                                React.createElement(Label, { htmlFor: color.id }, color.name))),
                            React.createElement(ListItemElement.OptionCode, null, color.optionNo),
                            React.createElement(ListItemElement.Chips, null,
                                color.standard ? React.createElement(OptionStandardChip, null) : React.createElement(ChipPlaceholder, null),
                                React.createElement(ChipPlaceholder, null)),
                            React.createElement(ListItemElement.Price, null, color.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, color.price)) : color.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, color.price)))))); }))))),
        React.createElement(RadioGroup.Root, { value: selectedWheels, onValueChange: onSelectedWheelsChange },
            React.createElement(Accordion.Item, { value: wheels.category, key: wheels.category },
                React.createElement(Accordion.Trigger, null, wheels.category),
                React.createElement(Accordion.Content, null,
                    React.createElement(List.Root, null, sortedWheels.availableOptions.map(function (wheel) { return (React.createElement(List.Item, { isSelected: wheel.id === selectedWheels, onClick: function (event) {
                            if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                                return;
                            }
                            onSelectedWheelsChange(wheel.id);
                        }, height: "fixed", key: wheel.id, isPrice: wheel.price !== null },
                        React.createElement(List.LayoutWithCodeAndChips, null,
                            React.createElement(LayoutWithWheels, { imageSrc: wheel.image },
                                React.createElement(RadioGroup.Item, { value: wheel.id, id: wheel.id },
                                    React.createElement(RadioGroup.Indicator, null)),
                                React.createElement(Label, { htmlFor: wheel.id }, wheel.name)),
                            React.createElement(ListItemElement.OptionCode, null, wheel.optionNo),
                            React.createElement(ListItemElement.Chips, null,
                                wheel.standard ? React.createElement(OptionStandardChip, null) : React.createElement(ChipPlaceholder, null),
                                React.createElement(ChipPlaceholder, null)),
                            React.createElement(ListItemElement.Price, null, wheel.price === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, wheel.price)) : wheel.price === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, wheel.price)))))); }))))))) : null));
});
