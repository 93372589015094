import React from 'react';
import './dialog.css';
import * as RadixDialog from '@radix-ui/react-dialog';
import { Close } from './Close.component';
function Root(_a) {
    var children = _a.children;
    return React.createElement(RadixDialog.Root, null, children);
}
function RootOpen(_a) {
    var children = _a.children, open = _a.open;
    return React.createElement(RadixDialog.Root, { open: open }, children);
}
function Trigger(_a) {
    var children = _a.children;
    return React.createElement(RadixDialog.Trigger, { className: 'dialogTrigger' }, children);
}
function Content(_a) {
    var children = _a.children;
    return (React.createElement(RadixDialog.Portal, { container: document.getElementById('new-dol') },
        React.createElement(RadixDialog.Overlay, { className: 'dialogOverlay' }),
        React.createElement(RadixDialog.Content, { className: 'dialogContent' }, children)));
}
function CloseButton() {
    return React.createElement(RadixDialog.Close, { className: 'dialogClose' },
        React.createElement(Close, { className: 'closeButton' }));
}
function CarPreviewContentLayout(_a) {
    var _b = _a.isDiffDialog, isDiffDialog = _b === void 0 ? false : _b, children = _a.children;
    return React.createElement("div", { className: "carPreviewContentLayout ".concat(isDiffDialog ? 'isDiffDialog' : '') }, children);
}
function PopupContent(_a) {
    var children = _a.children;
    return (React.createElement(RadixDialog.Portal, { container: document.getElementById('new-dol') },
        React.createElement(RadixDialog.Overlay, { className: 'dialogOverlay' }),
        React.createElement(RadixDialog.Content, { className: 'popupDialogContent' }, children)));
}
function PopupContentLayout(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'popupContentLayout' }, children);
}
function PopupTextWrapper(_a) {
    var children = _a.children;
    return React.createElement("div", { className: 'popupTextWrapper' },
        React.createElement("span", { className: 'popupInnerTextWrapper' }, children));
}
function PopupButtonWrapper(_a) {
    var children = _a.children, _b = _a.variant, variant = _b === void 0 ? 'single' : _b;
    return React.createElement("div", { className: "popupButtonWrapper ".concat(variant) }, children);
}
export var Dialog = {
    Root: Root,
    RootOpen: RootOpen,
    Trigger: Trigger,
    Content: Content,
    CloseButton: CloseButton,
    CarPreviewContentLayout: CarPreviewContentLayout,
    PopupContent: PopupContent,
    PopupContentLayout: PopupContentLayout,
    PopupButtonWrapper: PopupButtonWrapper,
    PopupTextWrapper: PopupTextWrapper
};
