import { withCancellation } from 'common/requestCancellation/requestCancellation';
import React from 'react';
import './newOfferFromStock.css';
import { NewConfigurationSection } from './sections/NewConfiguration/NewConfigurationSection.component';
import { SearchCarSection } from './sections/SearchCar/SearchCarSection.componeny';
import { CarsListSection } from './sections/CarsList/CarsListSection.component';
export var NewOfferFromStock = withCancellation(function () {
    return (React.createElement("div", { className: 'newOfferFromStockContainer' },
        React.createElement(NewConfigurationSection, null),
        React.createElement(SearchCarSection, null),
        React.createElement(CarsListSection, null)));
});
