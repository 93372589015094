import { Label } from 'components/label/Label.component';
import { List } from 'components/list/List.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import React from 'react';
import '../financialProducts.css';
export function FinancialProductConfigurationTab(_a) {
    var cfmProducts = _a.cfmProducts, selectedCfmProduct = _a.selectedCfmProduct, onCfpProductChange = _a.onCfpProductChange;
    return (React.createElement("div", { className: 'financialProductConfigurationWrapper' },
        React.createElement("div", { className: 'financialProductCategoryHeader' }, "Pakiet serwisowy"),
        cfmProducts ? (React.createElement(RadioGroup.Root, { value: selectedCfmProduct, onValueChange: onCfpProductChange },
            React.createElement(List.Root, null, cfmProducts.map(function (cfmProduct) { return (React.createElement(List.Item, { key: cfmProduct.id },
                React.createElement("div", { className: 'financialProductConfigurationItem' },
                    React.createElement(RadioGroup.Item, { value: cfmProduct.id, id: cfmProduct.id },
                        React.createElement(RadioGroup.Indicator, null)),
                    React.createElement(Label, { htmlFor: cfmProduct.id }, cfmProduct.name)))); })))) : null));
}
