import { Button } from 'components/button/Button.component';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import React from 'react';
export function SearchCarSection() {
    return (React.createElement("div", { className: 'searchCarSection' },
        React.createElement("div", { className: 'searchCarHeader' }, "Wyszukaj samoch\u00F3d:"),
        React.createElement(HorizontalLine, { color: '#D5D5D5' }),
        React.createElement("div", { className: 'stockCarSearchWrapper' }, "TODO: uk\u0142ad filtr\u00F3w i szukajki"),
        React.createElement(HorizontalLine, { color: '#D5D5D5' }),
        React.createElement("div", { className: 'filteringActionsWrapper' },
            React.createElement("div", { className: 'filtersSelectedWrapper' },
                React.createElement("div", { className: 'stockCarLabel' }, "Wybrane opcje:"),
                React.createElement("div", { className: 'filtersChips' },
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"),
                    React.createElement("div", { className: 'filterChips' }, "test1test1"))),
            React.createElement("div", { className: 'searchStockCarButtonsWrapper' },
                React.createElement("div", { className: 'searchStockCarButtons' },
                    React.createElement("div", null, "Wyczy\u015B\u0107 filtry"),
                    React.createElement("div", { className: 'searchStockCarButtonWrapper' },
                        React.createElement(Button, null, "wyszukaj")))))));
}
