import { useHttp } from 'http/httpService';
export function useFinancialProductsApi() {
    var http = useHttp();
    var apiPrefix = '/api/creator/leasing/cfg/products';
    return {
        getFinancialProductsList: function (page, isActive, phrase) {
            return http
                .get("".concat(apiPrefix), {
                params: {
                    page: page,
                    isActive: isActive,
                    phrase: phrase
                }
            })
                .then(function (response) { return response.data; });
        },
        activate: function (id, state) {
            return http
                .put("".concat(apiPrefix, "/").concat(id, "/").concat(state))
                .then(function (response) { return response.data; });
        },
        getProductDetails: function (id) {
            return http
                .get("".concat(apiPrefix, "/").concat(id))
                .then(function (response) { return response.data; });
        },
        getModels: function () {
            return http
                .get("".concat(apiPrefix, "/dict/cars"))
                .then(function (response) { return response.data; });
        },
        getPowerTrains: function () {
            return http
                .get("".concat(apiPrefix, "/dict/power-trains"))
                .then(function (response) { return response.data; });
        },
        saveFinancialProductConfig: function (id, excludeModelCars, excludePowerTrains, excludePno12) {
            return http
                .put("".concat(apiPrefix, "/").concat(id), {
                excludeModelCars: excludeModelCars,
                excludePowerTrains: excludePowerTrains,
                excludePno12: excludePno12
            });
        },
        default: function (id, state) {
            return http
                .put("".concat(apiPrefix, "/").concat(id, "/").concat(state))
                .then(function (response) { return response.data; });
        },
        getCfmProducts: function (id) {
            return http
                .get("".concat(apiPrefix, "/dict/").concat(id, "/cfm"))
                .then(function (response) { return response.data; });
        },
        selectCfmProduct: function (productId, cfmServiceProduct) {
            return http
                .put("".concat(apiPrefix, "/").concat(productId, "/select-cfm"), {
                cfmServiceProduct: cfmServiceProduct
            })
                .then(function (response) { return response.data; });
        },
        setDisclaimers: function (id, defaultDisclaimer, requestedDisclaimer) {
            return http
                .put("".concat(apiPrefix, "/").concat(id, "/disclaimers"), {
                defaultDisclaimer: defaultDisclaimer,
                requestedDisclaimer: requestedDisclaimer
            })
                .then(function (response) { return response.data; });
        }
    };
}
