import React from 'react';
import { Button } from 'components/button/Button.component';
import '../newOfferSections.css';
export function NewConfigurationSection() {
    function onNewConfigurationClick() {
    }
    ;
    return (React.createElement("div", { className: 'newConfigurationSection' },
        React.createElement("div", { className: 'newConfigurationButtonWrapper' },
            React.createElement(Button, { onClick: onNewConfigurationClick }, "utw\u00F3rz now\u0105 konfiguracj\u0119"))));
}
