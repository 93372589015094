import React from 'react';
import { Accordion } from 'components/accordion/Accordion.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { List } from 'components/list/List.component';
import { Label } from 'components/label/Label.component';
import { ListItemElement } from 'components/listItemElement/ListItemElement.component';
import { OptionStandardChip } from 'components/chip/OptionStandardChip.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import { TextAmountPlaceholder } from 'components/textAmount/TextAmountPlaceholder';
import { TextAmount } from 'components/textAmount/TextAmount';
import userContext from 'common/userContext';
import { LackOfPriceChip } from 'components/chip/LackOfPriceChip.component';
import { uppercasedFirstLetter } from 'utils/uppercasedFirstLetter';
function isFromVCP() {
    return userContext.isFromVCP();
}
function isEven(value) {
    return value % 2;
}
export function Version(_a) {
    var versions = _a.versions, selectedVersion = _a.selectedVersion, selectedTheme = _a.selectedTheme, onSelectedThemeChange = _a.onSelectedThemeChange, handleOnTokenChange = _a.handleOnTokenChange;
    function onThemeChange(value) {
        var _a = value.split(' '), versionId = _a[0], powerTrainThemeId = _a[1];
        var token = Object.values(versions).flat().find(function (theme) { return powerTrainThemeId === theme.powerTrainThemeId && versionId === theme.versionId; }).token;
        onSelectedThemeChange(value, token, true);
        handleOnTokenChange(token);
    }
    var versionsValues = Object.values(versions);
    function versionThemePairValueBuilder(version, theme) {
        return "".concat(version, " ").concat(theme);
    }
    return (React.createElement("div", { className: "versionsWrapper" }, versions ? (React.createElement(Accordion.Root, { defaultValue: versionsValues.map(function (version) { return version[0].versionId; }) },
        React.createElement(RadioGroup.Root, { value: versionThemePairValueBuilder(selectedVersion, selectedTheme), onValueChange: onThemeChange }, versionsValues.map(function (version) { return (React.createElement(Accordion.Item, { value: version[0].versionId, key: version[0].versionId },
            React.createElement(Accordion.Trigger, { backgroundColor: selectedVersion.split('-')[0] === version[0].versionId.split('-')[0]
                    ? isEven(version.findIndex(function (element) { return element.powerTrainThemeId === selectedTheme; }))
                        ? 'selectedEvenItem'
                        : 'selectedOddItem'
                    : 'default' }, version[0].versionName),
            React.createElement(Accordion.Content, null,
                React.createElement(List.Root, null, version.map(function (theme) { return (React.createElement(List.Item, { key: versionThemePairValueBuilder(theme.versionId, theme.powerTrainThemeId), isSelected: theme.versionId === selectedVersion &&
                        theme.powerTrainThemeId === selectedTheme, onClick: function (event) {
                        if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                            return;
                        }
                        onThemeChange(versionThemePairValueBuilder(theme.versionId, theme.powerTrainThemeId));
                    } },
                    React.createElement(List.LayoutWithCodeAndChips, null,
                        React.createElement(RadioGroup.Layout, null,
                            React.createElement(RadioGroup.Item, { value: versionThemePairValueBuilder(theme.versionId, theme.powerTrainThemeId), id: versionThemePairValueBuilder(theme.versionId, theme.powerTrainThemeId) },
                                React.createElement(RadioGroup.Indicator, null)),
                            React.createElement(Label, { htmlFor: versionThemePairValueBuilder(theme.versionId, theme.powerTrainThemeId) }, "Motyw: ".concat(uppercasedFirstLetter(theme.powerTrainThemeName)))),
                        React.createElement(ListItemElement.OptionCodePlaceholder, null),
                        React.createElement(ListItemElement.Chips, null,
                            theme.powerTrainThemePrice === 0.0 ? (React.createElement(OptionStandardChip, null)) : (React.createElement(ChipPlaceholder, null)),
                            React.createElement(ChipPlaceholder, null)),
                        React.createElement(ListItemElement.Price, null, theme.powerTrainThemePrice === null ? (React.createElement(LackOfPriceChip, null)) : isFromVCP() ? (React.createElement(TextAmount, { bold: true }, theme.powerTrainThemePrice)) : theme.powerTrainThemePrice === 0 ? (React.createElement(TextAmountPlaceholder, null)) : (React.createElement(TextAmount, { bold: true }, theme.powerTrainThemePrice)))))); }))))); })))) : null));
}
