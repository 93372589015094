var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { StockCarsListHeadRow } from './StockCarsListHeadRow';
import { PaginatedStockCars } from './PaginatedStockCars.component';
import { useStatus } from 'common/statusContext';
import ReactDOM from 'react-dom';
var mockCars = [
    {
        reservation: true,
        stockCategory: 'OWN_STOCK',
        model: 'XC40',
        modelYear: '2024',
        listPrice: 200000,
        type: 'OWN',
        dealerOrder: '7135323',
        vin: 'LYVUZK9V5PB317867',
        deliveryDate: '2024-04-14',
        lastDateForOrderChange: '2024-03-31',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10937770',
        productionDate: '2024-01-01',
        registrationNumber: null,
        comment: 'Doposażeniu auta w akcesoria pozafabryczne',
        warnings: [
            'Minęła data zamrożenia specyfikacji.',
            'Konfiguracja zostanie przedstawiona wg najnowszego cennika dla roku modelowego 2016.'
        ],
        oldCreatorAllowed: false
    },
    {
        reservation: false,
        stockCategory: 'OTHER_DEALERS_STOCK',
        model: 'XC60',
        modelYear: '2024',
        listPrice: 280000,
        type: 'TRADEABLE',
        dealerOrder: '7135666',
        vin: 'LYVUZK9V5PB317666',
        deliveryDate: '2024-12-25',
        lastDateForOrderChange: '2024-11-30',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10937666',
        productionDate: '2024-01-01',
        registrationNumber: 'ABC12312',
        comment: null,
        warnings: [
            'Minęła data zamrożenia specyfikacji.',
            'Konfiguracja zostanie przedstawiona wg najnowszego cennika dla roku modelowego 2016.',
            'Brak zapisanej konfiguracji w systemie. Utwórz ofertę samodzielnie.'
        ],
        oldCreatorAllowed: false
    },
    {
        reservation: false,
        stockCategory: 'OWN_STOCK',
        model: 'XC40',
        modelYear: '2024',
        listPrice: 200000,
        type: 'OWN',
        dealerOrder: '7135333',
        vin: 'LYVUZK9V5PB317333',
        deliveryDate: '2024-10-10',
        lastDateForOrderChange: '2024-09-01',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10937333',
        productionDate: '2024-01-01',
        registrationNumber: null,
        comment: 'Doposażeniu auta w akcesoria pozafabryczne',
        warnings: [],
        oldCreatorAllowed: false
    },
    {
        reservation: true,
        stockCategory: 'IMPORTER_STOCK',
        model: 'EC40',
        modelYear: '2024',
        listPrice: 330000,
        type: 'OTHER',
        dealerOrder: '7135777',
        vin: 'LYVUZK9V5PB317777',
        deliveryDate: '2024-07-07',
        lastDateForOrderChange: '2024-06-01',
        drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
        version: 'Wersja: Plus, Motyw: Bright Dust',
        upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
        interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
        dealerName: 'DOM VOLVO Warszawa Puławska',
        commonOrderNumber: '10937777',
        productionDate: '2024-01-01',
        registrationNumber: null,
        comment: null,
        warnings: [],
        oldCreatorAllowed: false
    },
];
var ITEMS_PER_PAGINATION_PAGE = 8;
export function CarsListSection() {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useState(mockCars), stockCars = _b[0], setStockCars = _b[1];
    var totalPages = 10;
    var _c = useState(0), currentPage = _c[0], setCurrentPage = _c[1];
    // TODO: paginacja po integracji z BE
    function changePageHandler(selectedPage) {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions;
            return __generator(this, function (_a) {
                setStatus('pending');
                requestOptions = {
                    // TODO: ustawienia do paginacji
                    page: selectedPage,
                    size: ITEMS_PER_PAGINATION_PAGE
                };
                // TODO: strzał po elementy jak w szkodach
                // const issuesResponse = await carDamageOCIssues.getIssues(isFromVcp, requestOptions);
                ReactDOM.unstable_batchedUpdates(function () {
                    // TODO: ustawić zwrócone elementy dla strony
                    // setIssues(issuesResponse.entries);
                    // setTotalPages(issuesResponse.totalPages);
                    setCurrentPage(selectedPage);
                    setStatus('success');
                });
                return [2 /*return*/];
            });
        });
    }
    return (React.createElement("div", { className: 'carsListSectionWrapper' },
        React.createElement("div", { className: 'carsListHeader' },
            React.createElement("div", { className: 'carsAmount' }, "Liczba samochod\u00F3w (120)"),
            React.createElement("div", { className: 'carsSortingWrapper' }, "TODO: dropdown do sortowania")),
        stockCars ? (React.createElement("div", { className: "stockCarsListContainer" },
            React.createElement(StockCarsListHeadRow, null),
            stockCars ? (React.createElement(PaginatedStockCars, { stockCars: stockCars, totalPages: totalPages, changePageHandler: changePageHandler, currentPage: currentPage })) : null)) : null));
}
